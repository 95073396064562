import React from 'react'

const About = () => {
    return (
        <>
            {/* About Section*/}
            <section className="page-section bg-primary text-white mb-0" id="about">
                <div className="container">
                    {/* About Section Heading*/}
                    <h2 className="page-section-heading text-center text-uppercase text-white">About</h2>
                    {/* Icon Divider*/}
                    <div className="divider-custom divider-light">
                        <div className="divider-custom-line"></div>
                        <div className="divider-custom-icon"><i className="fas fa-star"></i></div>
                        <div className="divider-custom-line"></div>
                    </div>
                    {/* About Section Content*/}
                    <div className="row">
                        <div className="col-lg-4 ms-auto"><p className="lead">Alacom LLC is a premier boutique software development firm dedicated to leveraging cutting-edge AI and machine learning technologies to empower businesses in reaching wider customer bases and driving significant revenue growth.</p></div>
                        <div className="col-lg-4 me-auto"><p className="lead">Get in touch with us today for a complimentary, no-obligation estimate tailored to your project and objectives. Discover how our expertise in AI and machine learning can propel your business forward. Call us at (425) 310-5239 to learn more.</p></div>
                    </div>

                </div>
            </section>
        </>
    )
}

export default About