import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter, faLinkedinIn, faDribbble } from '@fortawesome/free-brands-svg-icons';


const Footer = () => {
    return (
        <>
            {/* Footer*/}
            <footer className="footer text-center">
                <div className="container">
                    <div className="row">
                        {/* Footer Location*/}
                        <div className="col-lg-4 mb-5 mb-lg-0">
                            <h4 className="text-uppercase mb-4">Location</h4>
                            <p className="lead mb-0">
                                Seattle, WA
                            </p>
                        </div>
                        {/* Footer Social Icons*/}
                        <div className="col-lg-4 mb-5 mb-lg-0">
                            <h4 className="text-uppercase mb-4">Around the Web</h4>
                            <FontAwesomeIcon icon="coffee" />
                            <a className="btn btn-outline-light btn-social mx-1" href="#!"><FontAwesomeIcon icon={faFacebookF} /></a>
<a className="btn btn-outline-light btn-social mx-1" href="#!"><FontAwesomeIcon icon={faTwitter} /></a>
<a className="btn btn-outline-light btn-social mx-1" href="#!"><FontAwesomeIcon icon={faLinkedinIn} /></a>
<a className="btn btn-outline-light btn-social mx-1" href="#!"><FontAwesomeIcon icon={faDribbble} /></a>

                        </div>
                        {/* Footer About Text*/}
                        <div className="col-lg-4">
                            <h4 className="text-uppercase mb-4">About alacom LLC</h4>
                            <p className="lead mb-0">
                                alacom.ai is a branch of alacom LLC, a U.S based software design and development boutique serving national and international customers.
                            </p>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer