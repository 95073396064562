import React from 'react'

interface PortfolioModalProps {
    id: string;
    image: string;
}

const PortfolioModal = (props: PortfolioModalProps) => {
    return (
        <>
            {/* Portfolio Modal */}
            <div className="portfolio-modal modal fade" id={props.id} tabIndex={-1} aria-labelledby={props.id} aria-hidden="true">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header border-0"><button className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button></div>
                        <div className="modal-body text-center pb-5">
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col-lg-8">
                                        {/* Portfolio Modal - Title*/}
                                        <h2 className="portfolio-modal-title text-secondary text-uppercase mb-0">IoT Edge with AI & ML</h2>
                                        {/* Icon Divider*/}
                                        <div className="divider-custom">
                                            <div className="divider-custom-line"></div>
                                            <div className="divider-custom-icon"><i className="fas fa-star"></i></div>
                                            <div className="divider-custom-line"></div>
                                        </div>
                                        <p className="mb-4">Unlock the potential of IoT Edge technology with our advanced AI and machine learning solutions. From intelligent data processing to predictive analytics, we specialize in harnessing the power of IoT devices to drive efficiency, innovation, and business growth.</p>
                                        {/* Portfolio Modal - Image*/}
                                        <img className="img-fluid rounded mb-5" src={props.image} alt="..." />
                                        {/* Portfolio Modal - Text*/}
                                        {/* <p className="mb-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Mollitia neque assumenda ipsam nihil, molestias magnam, recusandae quos quis inventore quisquam velit asperiores, vitae? Reprehenderit soluta, eos quod consequuntur itaque. Nam.</p> */}
                                        <button className="btn btn-primary" data-bs-dismiss="modal">
                                            <i className="fas fa-xmark fa-fw"></i>
                                            Close Window
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default PortfolioModal