import React from 'react'
import avataaars from '../../assets/img/avataaars.svg';
import profile from '../../assets/img/profile.png';
const MastHead = () => {
    return (
        <>
            {/* Masthead*/}
            <header className="masthead bg-primary text-white text-center">
                <div className="container d-flex align-items-center flex-column">
                   
                    {/* <img className="masthead-avatar mb-5" src={avataaars} alt="..." /> */}
                    {/* Masthead Heading*/}
                    {/* <h1 className="masthead-heading text-lowercase mb-0">alacom.ai</h1> */}
                    {/* Icon Divider*/}
                    {/* <div className="divider-custom divider-light">
                        <div className="divider-custom-line"></div>
                        <div className="divider-custom-icon"><i className="fas fa-star"></i></div>
                        <div className="divider-custom-line"></div>
                    </div> */}
                    {/* Masthead Subheading*/}
                    {/* <p className="masthead-subheading font-weight-light mb-0">Revolutionize enterprise landscapes through AI, machine learning, and automation, unlocking data to fuel operational excellence and ignite innovative business models</p> */}
                </div>
            </header>
        </>
    )
}

export default MastHead