import React from 'react'

const Contact = () => {
    return (
        <>
            {/* Contact Section*/}
            <section className="page-section" id="contact">
                <div className="container">
                    {/* Contact Section Heading*/}
                    <h2 className="page-section-heading text-center text-uppercase text-secondary mb-0">Contact</h2>
                    {/* Icon Divider*/}
                    <div className="divider-custom">
                        <div className="divider-custom-line"></div>
                        <div className="divider-custom-icon"><i className="fas fa-star"></i></div>
                        <div className="divider-custom-line"></div>
                    </div>
                    {/* Contact Section Form*/}
                   <center>
                   <h3>US Phone: 425-310-5239</h3>
                   </center>
                </div>
            </section>
        </>
    )
}

export default Contact