import React from 'react'

interface PortfolioModalProps {
    id: string;
    image: string;
}

const PortfolioModal = (props: PortfolioModalProps) => {
    return (
        <>
            {/* Portfolio Modal */}
            <div className="portfolio-modal modal fade" id={props.id} tabIndex={-1} aria-labelledby={props.id} aria-hidden="true">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header border-0"><button className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button></div>
                        <div className="modal-body text-center pb-5">
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col-lg-8">
                                        {/* Portfolio Modal - Title*/}
                                        <h2 className="portfolio-modal-title text-secondary text-uppercase mb-0">AI Solutions for Business Enhancement</h2>
                                        {/* Icon Divider*/}
                                        <div className="divider-custom">
                                            <div className="divider-custom-line"></div>
                                            <div className="divider-custom-icon"><i className="fas fa-star"></i></div>
                                            <div className="divider-custom-line"></div>
                                        </div>
                                           {/* Portfolio Modal - Text*/}
                                           <p className="mb-4">Empower your business with tailored AI solutions. Our expertise in AI unlocks new avenues for growth, from predictive analytics to automated decision-making. Harness the power of machine learning and data-driven insights to optimize operations, enhance customer experiences, and drive strategic decision-making. Partner with us to unlock the full potential of AI for your business enhancement needs.</p>
                                   
                                        {/* Portfolio Modal - Image*/}
                                        <img className="img-fluid rounded mb-5" src={props.image} alt="..." />
                                          <button className="btn btn-primary" data-bs-dismiss="modal">
                                            <i className="fas fa-xmark fa-fw"></i>
                                            Close Window
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default PortfolioModal