import iotedge from '../../assets/img/iotedge.webp';
import webdev from '../../assets/img/webdev.webp';
import os from '../../assets/img/os.webp';
import db from '../../assets/img/db.webp';
import mobile from '../../assets/img/mobile.webp';
import ml from '../../assets/img/ml.webp';
import ai from '../../assets/img/ai.webp';
import PortfolioModal1 from '../PortfolioModal/PortfolioModal1';
import PortfolioModal2 from '../PortfolioModal/PortfolioModal2';
import PortfolioModal3 from '../PortfolioModal/PortfolioModal3';
import PortfolioModal4 from '../PortfolioModal/PortfolioModal4';
import PortfolioModal5 from '../PortfolioModal/PortfolioModal5';
import PortfolioModal6 from '../PortfolioModal/PortfolioModal6';
import PortfolioModal7 from '../PortfolioModal/PortfolioModal7';

const Portfolio = () => {
    const services = [
        "IoT Edge with AI & ML", 
        "Web development with AI integration", 
        "OS Agnostic AI & ML development",         
        "Database development and automation",      
        "Machine Learning model deployment", 
        "Mobile applications with AI",        
        "AI Solutions for Business Enhancement"]; 

        const models = [
             '#portfolioModal1' ,
             '#portfolioModal2' ,
             '#portfolioModal3' ,
             '#portfolioModal4' ,
             '#portfolioModal5' ,
             '#portfolioModal6',
             '#portfolioModal7' 
        ]
    return (
        <>
            {/* Portfolio Section*/}
            <section className="page-section portfolio" id="portfolio">
                <div className="container">
                    {/* Portfolio Section Heading*/}
                    <h2 className="page-section-heading text-center text-uppercase text-secondary mb-0">Services</h2>
                    {/* Icon Divider*/}
                    <div className="divider-custom">
                        <div className="divider-custom-line"></div>
                        <div className="divider-custom-icon"><i className="fas fa-star"></i></div>
                        <div className="divider-custom-line"></div>
                    </div>
                    {/* Portfolio Grid Items*/}
                    {services.map((service, index) => (
                    <div className="portfolio-item mx-auto" data-bs-toggle="modal" data-bs-target={models[index]}>
                          {/* <div className="portfolio-item-caption d-flex align-items-center justify-content-center h-100 w-100">
                              <div className="portfolio-item-caption-content text-center text-white"><i className="fas fa-plus fa-3x"></i></div>
                          </div> <br></br> */}
                          <span className="portfolio-item">{service}</span>
                         
                    </div> 
                    ))}
                </div>
            </section>

            {/* Hiddem Modals */}
            <PortfolioModal1 id='portfolioModal1' image={iotedge} />
            <PortfolioModal2 id='portfolioModal2' image={webdev} />
            <PortfolioModal3 id='portfolioModal3' image={os} />
            <PortfolioModal4 id='portfolioModal4' image={db} />
            <PortfolioModal5 id='portfolioModal5' image={ml} />
            <PortfolioModal6 id='portfolioModal6' image={mobile} />
            <PortfolioModal7 id='portfolioModal7' image={ai} />
        </>
    )
}

export default Portfolio