import React from 'react'

interface PortfolioModalProps {
    id: string;
    image: string;
}

const PortfolioModal = (props: PortfolioModalProps) => {
    return (
        <>
            {/* Portfolio Modal */}
            <div className="portfolio-modal modal fade" id={props.id} tabIndex={-1} aria-labelledby={props.id} aria-hidden="true">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header border-0"><button className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button></div>
                        <div className="modal-body text-center pb-5">
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col-lg-8">
                                        {/* Portfolio Modal - Title*/}
                                        <h2 className="portfolio-modal-title text-secondary text-uppercase mb-0">Machine learning model deployment</h2>
                                        {/* Icon Divider*/}
                                        <div className="divider-custom">
                                            <div className="divider-custom-line"></div>
                                            <div className="divider-custom-icon"><i className="fas fa-star"></i></div>
                                            <div className="divider-custom-line"></div>
                                        </div>
                                           {/* Portfolio Modal - Text*/}
                                           <p className="mb-4">Effortlessly deploy your machine learning models with our specialized services. We provide end-to-end solutions for deploying machine learning models into production environments, ensuring seamless integration and optimal performance. From selecting the right deployment strategy to monitoring and maintenance, we handle every aspect to ensure your models deliver real-world value. Trust us to streamline the deployment process and unlock the full potential of your machine learning initiatives.</p>
                                   
                                        {/* Portfolio Modal - Image*/}
                                        <img className="img-fluid rounded mb-5" src={props.image} alt="..." />
                                          <button className="btn btn-primary" data-bs-dismiss="modal">
                                            <i className="fas fa-xmark fa-fw"></i>
                                            Close Window
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default PortfolioModal